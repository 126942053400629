<template>
  <div class="costshopping">
    <h1>This is an 成本购样 page</h1>
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  }
}
</script>
<style scoped>

</style>